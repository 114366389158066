import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CallIcon from "@material-ui/icons/Call";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_MAIN, TELEPHONE_NUMBER, TELEPHONE_NUMBER_HREF } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    lineHeight: 1.3,
    textDecoration: 'none',
    color: 'inherit',
  },
  phone: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "inherit",
    align: "right",
  },
}));

export default function Navigation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component={RouterLink} className={classes.title} to={ROUTE_MAIN}>
            West Oxfordshire Podiatry
          </Typography>
          <Typography>
            <Link href={TELEPHONE_NUMBER_HREF} className={classes.phone}>
              <CallIcon fontSize="small" />
              &nbsp;
              <span itemProp="telephone">{TELEPHONE_NUMBER}</span>
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
