import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Container } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
// }));

export default function Availability() {
  // const classes = useStyles();
  const heading = "Availability & Appointments";

  return (
    <div>
      <Container maxWidth="md">
        <Box pb="1rem">
          <Typography variant="h2" align="center" gutterBottom>
            {heading}
          </Typography>
        </Box>
        <Box p="0.5rem">
          <Typography variant="body2">
            I provide a prompt service. Normally I can see a new client within
            two weeks, often sooner.
          </Typography>
        </Box>
        <Box p="0.5rem">
          <Typography variant="body2">
            Please phone, or use the contact form to book an appointment.
          </Typography>
        </Box>
        <Box p="0.5rem">
          <Typography variant="body2">
            Appointments include a full and confidential record, assessment of
            condition and all necessary treatment required, in a courteous and
            sympathetic atmosphere.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
