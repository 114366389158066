import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: "2rem",
    lineHeight: "1.5",
  },
  page: {
    paddingTop: "2rem",
    "h1, h2, h3": {
      marginBottom: "1.5em",
    },
    "p": {
      marginBottom: "1em",
    },
  },
}));

export default function Cookies() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.page}>
      <Box className={classes.section}>
        <Typography variant="h1">Cookie Policy</Typography>
        <Typography variant="body1">
          This cookie policy (&quot;Policy&quot;) describes what cookies are and
          how and they&#039;re being used by the{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://westoxfordshirepodiatry.co.uk"
          >
            westoxfordshirepodiatry.co.uk
          </a>{" "}
          website and its aliases at{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://martindrew-chiropodist.co.uk/"
          >
            martindrew-chiropodist.co.uk
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://martindrewpodiatry.co.uk/"
          >
            martindrewpodiatry.co.uk
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://martindrewpodiatrist.co.uk/"
          >
            martindrewpodiatrist.co.uk
          </a>{" "}
          (&quot;Website&quot; or &quot;Websites&quot; or &quot;Service&quot;)
          and any of its related products and services (collectively,
          &quot;Services&quot;). This Policy is a legally binding agreement
          between you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;)
          and this Website operator (&quot;Operator&quot;, &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot;). You should read this Policy so you
          can understand the types of cookies we use, the information we collect
          using cookies and how that information is used. It also describes the
          choices available to you regarding accepting or declining the use of
          cookies. For further information on how we use, store and keep your
          personal data secure, see our{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://westoxfordshirepodiatry.co.uk/privacy"
          >
            privacy policy
          </a>
          .
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">What are cookies?</Typography>
        <Typography variant="body1">
          Cookies are small pieces of data stored in text files that are saved
          on your computer or other devices when websites are loaded in a
          browser. They are widely used to remember you and your preferences,
          either for a single visit (through a &quot;session cookie&quot;) or
          for multiple repeat visits (using a &quot;persistent cookie&quot;).
        </Typography>
        <Typography variant="body1">
          Session cookies are temporary cookies that are used during the course
          of your visit to the Website, and they expire when you close the web
          browser.
        </Typography>
        <Typography variant="body1">
          Persistent cookies are used to remember your preferences within our
          Website and remain on your desktop or mobile device even after you
          close your browser or restart your computer. They ensure a consistent
          and efficient experience for you while visiting the Website and
          Services.
        </Typography>
        <Typography variant="body1">
          Cookies may be set by the Website (&quot;first-party cookies&quot;),
          or by third parties, such as those who serve content or provide
          advertising or analytics services on the Website (&quot;third party
          cookies&quot;). These third parties can recognize you when you visit
          our website and also when you visit certain other websites.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">What type of cookies do we use?</Typography>
        <Typography variant="h3">Third Party and Analytical cookies</Typography>
        <Typography variant="body1">
          These cookies enable us to use Google Analytics, a third party
          service, to collect aggregated data for statistical purposes on how
          our visitors use the Website. These cookies do not contain personal
          information such as names and email addresses and are used to help us
          improve your user experience of the Website, and to measure
          Engagement.
        </Typography>
        <Typography variant="body1">
          More detailed information about Google Analytics cookies can be found
          <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
            under 'cookie usage' on their website
          </a>
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">What are your cookie options?</Typography>
        <Typography variant="body1">
          If you don't like the idea of cookies or certain types of cookies, you
          can change your browser's settings to delete cookies that have already
          been set and to not accept new cookies. Visit{" "}
          <a
            target="_blank"
            href="https://www.internetcookies.com"
            rel="noopener noreferrer"
          >
            internetcookies.com
          </a>{" "}
          to learn more about how to do this.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">Changes and amendments</Typography>
        <Typography variant="body1">
          We reserve the right to modify this Policy or its terms relating to
          the Website and Services at any time, effective upon posting of an
          updated version of this Policy on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of
          the Website and Services after any such changes shall constitute your
          consent to such changes.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">Acceptance of this policy</Typography>
        <Typography variant="body1">
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h2">Contacting us</Typography>
        <Typography variant="body1">
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to our use of
          cookies, you may do so via the{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="westoxfordshirepodiatry.co.uk/"
          >
            contact form
          </a>{" "}
          or send an email to{" "}
          <a href="mailto:martindrew889@gmail.com">martindrew889@gmail.com</a>.
        </Typography>
        <Typography variant="body1">
          This document was last updated on{" "}
          <time dateTime="2021-04-27">April 27, 2021</time>{" "}
        </Typography>
      </Box>
    </Container>
  );
}
