import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Container, Grid, List, ListItem } from "@material-ui/core";
import Map from "../Map/Map";

// const useStyles = makeStyles((theme) => ({
// }));

export default function AreaCovered() {
  // const classes = useStyles();

  return (
    <div itemProp="areaServed" itemScope itemType="https://schema.org/Place">
      <div itemProp="geo" itemScope itemType="https://schema.org/GeoCircle">
        <div
          itemProp="geoMidpoint"
          itemScope
          itemType="https://schema.org/GeoCoordinates"
        >
          <meta itemProp="latitude" content="51.7273" />
          <meta itemProp="longitude" content="-1.5450" />
        </div>
        <meta itemProp="geoRadius" content="12000" />
      </div>
      <Container maxWidth="md">
        <Box p="1rem">
          <Typography variant="h2" align="center" gutterBottom>
            Proudly serving the <span itemProp="name">West Oxfordshire</span>{" "}
            community
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem>Witney</ListItem>
              <ListItem>Carterton</ListItem>
              <ListItem>Faringdon</ListItem>
              <ListItem>Fairford</ListItem>
              <ListItem>Burford</ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem>Lechlade</ListItem>
              <ListItem>Standlake</ListItem>
              <ListItem>Southmoor</ListItem>
              <ListItem>Kingston Bagpuize</ListItem>
              <ListItem>
                <b>And all Surrounding Areas</b>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Map />
      </Container>
    </div>
  );
}
