import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Container } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
// }));

export default function HomeVisits() {
  // const classes = useStyles();
  const heading = "Home Visits";

  return (
    <div>
      <Container maxWidth="md">
        <Box pb="1rem">
          <Typography variant="h2" align="center" gutterBottom>
            {heading}
          </Typography>
        </Box>
        <Box p="0.5rem">
          <Typography variant="body2">
            Home visits are undertaken with strict hygiene procedure.
            Instruments are autoclaved and wrapped and are single use. Hand
            washing and sanitization is a prerequisite to wearing disposable
            gloves, and again after treatments are finished.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
