import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import {
  EMAIL_ADDRESS,
  EMAIL_ADDRESS_HREF,
  TELEPHONE_NUMBER,
  TELEPHONE_NUMBER_HREF,
  ROUTE_COOKIES,
  ROUTE_PRIVACY
} from "../../config";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  footerLink: {
    minHeight: "2rem",
  },
}));

function Copyright() {
  return (
    <Typography
      variant="caption"
      display="block"
      color="inherit"
      align="center"
    >
      {"Copyright © Martin Drew 2021"}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();
  const footers = [
    {
      link: "https://www.freepik.com/photos/spa",
      text: "Spa photo created by valuavitaly - www.freepik.com",
    },
  ];

  return (
    <Container maxWidth="xl" component="footer" className={classes.footer}>
      <Box mt={5} pt={2}>
        <div
          itemProp="founder"
          itemScope
          itemType="https://schema.org/Person"
          itemID="MartinDrew"
        >
          <Link
            className={classes.footerLink}
            itemProp="memberOf"
            itemScope
            itemType="https://schema.org/ProgramMembership"
            variant="caption"
            color="inherit"
            href="https://www.hcpc-uk.org/check-the-register/professional-registration-detail/?query=CH19287&profession=CH"
            display="block"
            align="center"
            rel="noreferrer noopener"
            gutterBottom
          >
            <span
              itemProp="hostingOrganization"
              itemScope
              itemType="https://schema.org/Organization"
            >
              <meta itemProp="url" content="https://www.hcpc-uk.org" />
              <span itemProp="name" title="Health & Care Professions Council">
                HCPC
              </span>
            </span>{" "}
            Registered Healthcare Professional (
            <span itemProp="membershipNumber">CH19287</span>)
          </Link>
        </div>
        <Link
          className={classes.footerLink}
          variant="caption"
          color="inherit"
          href={EMAIL_ADDRESS_HREF}
          display="block"
          align="center"
          rel="noreferrer noopener"
          gutterBottom
        >
          Email: <span itemProp="email">{EMAIL_ADDRESS}</span>
        </Link>
        <Link
          className={classes.footerLink}
          variant="caption"
          color="inherit"
          href={TELEPHONE_NUMBER_HREF}
          display="block"
          align="center"
          rel="noreferrer noopener"
          gutterBottom
        >
          Phone: {TELEPHONE_NUMBER}
        </Link>
        <Link
          component={RouterLink}
          className={classes.footerLink}
          variant="caption"
          color="inherit"
          to={ROUTE_COOKIES}
          display="block"
          align="center"
          rel="noreferrer noopener"
          gutterBottom
        >
          Cookie Policy
        </Link>
        <Link
          component={RouterLink}
          className={classes.footerLink}
          variant="caption"
          color="inherit"
          to={ROUTE_PRIVACY}
          display="block"
          align="center"
          rel="noreferrer noopener"
          gutterBottom
        >
          Privacy Policy
        </Link>
        {footers.map((footer) => (
          <Link
            className={classes.footerLink}
            variant="caption"
            color="inherit"
            key={footer.text}
            href={footer.link}
            display="block"
            align="center"
            rel="noreferrer noopener"
            gutterBottom
          >
            {footer.text}
          </Link>
        ))}
      </Box>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}
