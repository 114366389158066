import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Grid, Avatar, Container } from "@material-ui/core";
import bio1xjpg from "./imgs/profile@1x.jpg";
import bio2xwebp from "./imgs/profile@2x.webp";
import bio1xwebp from "./imgs/profile@1x.webp";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "20rem",
    width: "20rem",
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      height: "12rem",
      width: "12rem",
    },
  },
}));

export default function About() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div>
      <meta itemProp="image" content={bio2xwebp} />
      <Container
        maxWidth="md"
        itemProp="founder"
        itemScope
        itemType="https://schema.org/Person"
        itemID="MartinDrew"
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box pb="1rem">
              <meta itemProp="image" content={bio2xwebp} />
              <Avatar
                alt="Martin Drew, Podiatrist"
                srcSet={`${bio2xwebp} 2x, ${bio1xwebp} 1x`}
                src={bio1xjpg}
                className={classes.avatar}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h2"
              align="center"
              display="block"
              gutterBottom
            >
              <span itemProp="name">Martin Drew</span>&nbsp;—&nbsp;
              <span itemProp="jobTitle">Podiatrist</span>
            </Typography>
            <Typography
              variant="body1"
              display="block"
              align="center"
              gutterBottom
              itemProp="honorificSuffix"
            >
              <span
                title="Fellowship of the School of Surgical Chiropodists"
                itemProp="hasCredential"
                itemScope
                itemType="https://schema.org/EducationalOccupationalCredential"
              >
                <span
                  itemProp="credentialCategory"
                  itemScope
                  itemType="https://schema.org/DefinedTerm"
                >
                  <span itemProp="name">F.SS.Ch</span>
                  <meta
                    itemProp="description"
                    content="Fellowship of the School of Surgical Chiropodists"
                  />
                </span>
              </span>
              ,{" "}
              <span
                title="Diploma in Podiatric Medicine"
                itemProp="hasCredential"
                itemScope
                itemType="https://schema.org/EducationalOccupationalCredential"
              >
                <span
                  itemProp="credentialCategory"
                  itemScope
                  itemType="https://schema.org/DefinedTerm"
                >
                  <span itemProp="termCode">DipPodMed</span>
                  <meta
                    itemProp="name"
                    content="Diploma in Podiatric Medicine"
                  />
                </span>
              </span>
              ,{" "}
              <span
                title="Member of the British Chiropody Association"
                itemProp="memberOf"
                itemScope
                itemType="https://schema.org/Organization"
              >
                <span itemProp="description">MBChA</span>
                <meta itemProp="name" content="British Chiropody Association" />
                <meta itemProp="url" content="https://bcha-uk.org/" />
              </span>
            </Typography>
            <Box p="1rem">
              <Typography variant="body2" align={matches ? "left" : "center"}>
                I have been practising since 1990, having looked after many
                nursing homes, businesses and even a football club, as well as
                many clients in the community.
              </Typography>
              <Typography variant="body2" align={matches ? "left" : "center"}>
                It is a thoroughly satisfying occupation, helping to keep people
                mobile, so they can live life to the full.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
