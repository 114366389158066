import React from "react";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  treatmentDescription: {
    paddingLeft: "2rem",
    paddingBottom: "0.5rem",
  },
  treatmentName: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Treatment({
  serviceType,
  children,
  audience = [
    "Everyone",
    "Elderly Persons",
    "Geriatrics",
    "Disabled Persons",
    "Care Homes",
  ],
}) {
  const styles = useStyles();
  return (
    <div itemProp="makesOffer" itemScope itemType="https://schema.org/Offer">
      <div
        itemProp="itemOffered"
        itemScope
        itemType="https://schema.org/Service"
      >
        <Typography
          itemProp="serviceType"
          variant="h3"
          color="secondary"
          className={styles.treatmentName}
          gutterBottom
        >
          <CheckIcon fontSize="small" />
          &nbsp;{serviceType}
        </Typography>
        <Typography
          itemProp="description"
          variant="body2"
          className={styles.treatmentDescription}
          gutterBottom
        >
          {children}
        </Typography>
        <div
          itemProp="audience"
          itemScope
          itemType="https://schema.org/Audience"
        >
          {audience.map((aud) => (
            <meta
              key={`aud_${aud}`}
              itemProp="audienceType"
              name="audienceType"
              content={aud}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
