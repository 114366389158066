import React, { useRef, useEffect } from "react";
import "ol/ol.css";
import OpenLayersMap from "ol/Map";
import { OSM, Vector } from "ol/source";
import { Tile, Vector as VectorLayer } from "ol/layer";
import { Feature, View } from "ol";
import { useGeographic as xxx } from "ol/proj";
import { circular } from "ol/geom/Polygon";
xxx();

export default function Map({
  lat = 51.74,
  lon = -1.58,
  zoom = 7,
  minZoom = -10,
  maxZoom = 100,
  extent = 0.5,
}) {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef) return;

    // Clear out content otherwise browsersync causes maps to pile up.
    mapRef.current.innerHTML = "";

    // Focus on the interesting part of the map and prevent scrolling too far away
    const view = new View({
      center: [lon, lat],
      zoom,
      maxZoom,
      extent: [lon - extent, lat - extent, lon + extent, lat + extent],
    });
    var circularPolygon = circular(view.getCenter(), 20000, 64);
    // Source and vector layer
    var vectorSource = new Vector({
      projection: "EPSG:4326",
      features: [new Feature(circularPolygon)],
    });
    var vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    // Draw the map into the mapRef element using OpenStreetMap
    new OpenLayersMap({
      layers: [new Tile({ source: new OSM() }), vectorLayer],
      target: mapRef.current,
      view,
    });

    setTimeout(() => {
      mapRef.current
        .querySelectorAll("a")
        .forEach((it) => it.setAttribute("rel", "noopener noreferer"));
    }, 0);
  }, [mapRef, lat, lon, extent, zoom, minZoom, maxZoom]);

  return <div ref={mapRef} style={{ height: "30rem" }}></div>;
}
