import React, { useEffect, useState } from "react";

export function GoogleAnalytics({
    tagName = 'script',
    layerName = 'dataLayer',
    tagManagerId,
    enabled
}) {
    const [preLoaded, setPreLoaded] = useState(false);
    useEffect(() => {
        if (!preLoaded) {
            window[layerName] = window[layerName] || [];
            window[layerName].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
        }
        setPreLoaded(true);
    }, [preLoaded, layerName]);

    useEffect(() => {
        const firstScriptTag = document.getElementsByTagName(tagName)[0];
        if (enabled && firstScriptTag.src.indexOf('googletagmanager') === -1) {
            const analyticsScriptTag = document.createElement(tagName);
            const layerQuery = layerName !== 'dataLayer' ? '&l=' + layerName : '';
            analyticsScriptTag.async = true;
            analyticsScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + tagManagerId + layerQuery;
            firstScriptTag.parentNode.insertBefore(analyticsScriptTag, firstScriptTag);
        }
    }, [layerName, tagName, tagManagerId, enabled]);

    return <noscript>
        {enabled && <iframe title="GoogleAnalytics" src={`https://www.googletagmanager.com/ns.html?id=${tagManagerId}`} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }} />}
    </noscript>;
}
