import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import Navigation from "./components/Navigation/Navigation";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import Cookies from "./components/Cookies/Cookies";
import { ROUTE_COOKIES, ROUTE_PRIVACY } from "./config";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { GoogleAnalytics } from "./GoogleAnalytics";

const gtagManagerId = 'GTM-P75875V';

function App() {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue())
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div itemScope itemType="https://schema.org/LocalBusiness">
          <CssBaseline />
          <Navigation />
          <Routes>
            <Route path={ROUTE_PRIVACY} element={<Privacy/>} />
            <Route path={ROUTE_COOKIES} element={<Cookies/>} />
            <Route path="/" element={<Home/>} />
          </Routes>
          <Footer />
        </div>
      </ThemeProvider>

      <CookieConsent enableDeclineButton onAccept={_ => setCookieConsent(true)} onDecline={_ => setCookieConsent(false)}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <GoogleAnalytics tagManagerId={gtagManagerId} enabled={cookieConsent} />
    </Router>
  );
}

export default App;
