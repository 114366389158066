import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Grid, Container, Button } from "@material-ui/core";
import About from "../About/About";
import Treatments from "../Treatments/Treatments";
import AreaCovered from "../AreaCovered/AreaCovered";
import Availability from "../Availability/Availability";
import Contact from "../Contact/Contact";
import { Link as ScrollLink } from 'react-scroll'
import feet1xjpg from "./imgs/feet@1x.jpg";
import feet2xjpg from "./imgs/feet@2x.jpg";
import feet1xwebp from "./imgs/feet@1x.webp";
import feet2xwebp from "./imgs/feet@2x.webp";
import HomeVisits from "../HomeVisits/HomeVisits";

const useStyles = makeStyles((theme) => ({
  topSection: {
    marginTop: "10px",
    minHeight: "400px",
    overflow: "hidden",
  },
  hero: {
    paddingLeft: "20px",
    paddingTop: "20px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "80px",
      paddingTop: "80px",
    },
  },
  feet: {
    maxHeight: "26rem",
    marginBottom: "-10px",
  },
  strapline: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "75%",
    },
  },
  greySection: {
    backgroundColor: "#fafafa",
    paddingBottom: "2rem",
  },
  whiteSection: {
    backgroundColor: "#fefeff",
    paddingBottom: "2rem",
  },
  topSectionImage: {
    display: "flex",
    justifyContent: "center",
  },
  getInTouchButton: {
    margin: '2rem 0'
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container className={classes.topSection}>
          <Grid item sm={6} xs={12}>
            <Box className={classes.hero}>
              <Typography itemProp="name" variant="h1" gutterBottom>
                West Oxfordshire Podiatry
              </Typography>
              <Typography variant="body1" className={classes.strapline}>
                Private practice established in 1990, serving the West
                Oxfordshire area
              </Typography>
              <Typography className={classes.getInTouchButton}>
                <Button color="primary" variant="contained" component={ScrollLink} smooth="true" to="contact">
                  Get In Touch
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.topSectionImage}>
            <picture itemProp="image" className={classes.feet}>
              <source
                srcSet={`${feet2xwebp} 2x, ${feet1xwebp} 1x`}
                type="image/webp"
              />
              <img
                width="341"
                title="Make your feet happy"
                alt="Get healthy feet"
                srcSet={`${feet2xjpg} 2x, ${feet1xjpg} 1x`}
                src={feet2xjpg}
              />
            </picture>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Box pt="40px" className={classes.greySection}>
        <About />
      </Box>
      <Divider />
      <Box pt="40px" className={classes.whiteSection}>
        <Treatments />
      </Box>
      <Divider />
      <Box pt="40px" className={classes.greySection}>
        <AreaCovered />
      </Box>
      <Divider />
      <Box pt="40px" className={classes.whiteSection}>
        <Availability />
      </Box>
      <Box pt="40px" className={classes.whiteSection}>
        <HomeVisits />
      </Box>
      <Box id="contact" pt="40px" className={classes.whiteSection}>
        <Contact />
      </Box>
    </div>
  );
}
