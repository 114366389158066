import {
  Box,
  Button,
  CircularProgress,
  Container,
  Icon,
  TextField,
  makeStyles,
} from "@material-ui/core";
import {
  EMAIL_ADDRESS,
  EMAIL_ADDRESS_HREF,
  TELEPHONE_NUMBER,
  TELEPHONE_NUMBER_HREF,
} from "../../config";
import React, { useCallback, useState } from "react";

import Alert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import theme from "../../theme";

const contactFormTarget = "/contact";
const SENDING = "Sending...";
const SENT =
  "Your message has been sent, and a confirmation will arrive in your email inbox shortly";
const SEND_FAILED =
  'Uh Oh! Something went wrong! Click "SEND" again to retry, or phone us to get in touch.';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    border: "1px solid rgba(27,31,35,.15)",
  },
}));

function sendContactRequest(body) {
  return fetch(contactFormTarget, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((res) => {
    if (200 <= res.status && res.status < 300) {
      return res;
    } else {
      throw new Error("Error response from server");
    }
  });
}

export default function Contact() {
  const classes = useStyles();
  const heading = "Get in Touch";

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [subject, setSubject] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [sendingStatus, setSendingStatus] = useState(null);

  const handleChangeEmail = useCallback(
    (e) => {
      setEmailError(e.target.value.indexOf("@") === -1);
      setEmail(e.target.value);
    },
    [setEmail, setEmailError]
  );

  const handleChangePhone = useCallback(
    (e) => {
      setPhoneError(e.target.value.length < 6);
      setPhone(e.target.value);
    },
    [setPhone, setPhoneError]
  );

  const formSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setEmailError(null);
      setPhoneError(null);
      setNameError(null);
      setMessageError(null);
      if(!name) {
        setNameError("Please enter your name");
      }
      if(!message && !subject) {
        setMessageError("Please let me know what you need");
      }
      if(!email && !phone) {
        setEmailError("We need some way to get in touch, please leave your email and/or your phone number");
        setPhoneError("We need some way to get in touch, please leave your email and/or your phone number");
        return;
      }
      if(email && !email.includes('@')) {
        setEmailError("This email address doesn't look real, please check you've included an @ symbol");
      }
      if(phone && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(phone)) {
        setPhoneError("Please double check your phone number, make sure to include area code and don't use spaces");
      }
      setSendingStatus(SENDING);
      sendContactRequest({ message, name, email, subject, phone }).then(
        () => setSendingStatus(SENT),
        () => setSendingStatus(SEND_FAILED)
      );
    },
    [message, name, email, subject, phone, setSendingStatus]
  );

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h2" align="center" gutterBottom>
          {heading}
        </Typography>

        <Typography variant="body2" align="center" gutterBottom>
          To get in touch you can Phone{" "}
          <a href={TELEPHONE_NUMBER_HREF}>{TELEPHONE_NUMBER}</a>, <br />
          Email <a href={EMAIL_ADDRESS_HREF}>{EMAIL_ADDRESS}</a>, <br />
          or you can use the contact form below.
        </Typography>
        <Box p="1rem">
          <form onSubmit={formSubmit}>
            <Box marginBottom="1rem">
              <TextField
                fullWidth
                placeholder="Enter your name"
                label="Name"
                name="full-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                error={nameError}
                helperText={nameError}
                type="text"
              />
              <TextField
                fullWidth
                label="Telephone"
                placeholder="Enter your phone number"
                value={phone}
                name="phone"
                onChange={(e) => handleChangePhone(e)}
                error={phoneError}
                helperText={phoneError}
                required
                type="tel"
              />
              <TextField
                fullWidth
                label="Email"
                placeholder="Enter email address"
                value={email}
                name="email"
                onChange={(e) => handleChangeEmail(e)}
                error={emailError}
                helperText={emailError}
                required
                type="email"
              />
              <Autocomplete
                freeSolo
                options={[
                  "I have a question",
                  "I'd like to book an appointment",
                  "I'd like to cancel an appointment",
                ]}
                classes={{ paper: classes.autocompleteContainer }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Enter Subject"
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    type="text"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Message"
                placeholder="Enter Message"
                autoComplete="off"
                multiline={true}
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                type="text"
                error={messageError}
                helperText={messageError}
              />
              {sendingStatus === SENT && (
                <Alert severity="success">{sendingStatus}</Alert>
              )}
              {sendingStatus === SEND_FAILED && (
                <Alert severity="error">{sendingStatus}</Alert>
              )}
            </Box>
            <Button
              size="large"
              variant="contained"
              style={{
                background:
                  sendingStatus === SEND_FAILED ? theme.palette.error.main : "",
              }}
              color="primary"
              disabled={sendingStatus === SENDING || sendingStatus === SENT}
              onClick={formSubmit}
              endIcon={
                <>
                  {sendingStatus === SENDING ? (
                    <CircularProgress />
                  ) : sendingStatus === SENT ? (
                    <Icon>check</Icon>
                  ) : sendingStatus === SEND_FAILED ? (
                    <Icon>error</Icon>
                  ) : (
                    <Icon>send</Icon>
                  )}
                </>
              }
            >
              {sendingStatus === SENDING ? (
                <span>{sendingStatus}</span>
              ) : (
                <span>Send</span>
              )}
            </Button>
          </form>
        </Box>
      </Container>
    </div>
  );
}
