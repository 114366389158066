import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let colours = {
  theme: {
    primary: "#3c9cd0",
    secondary: "#0981B9",
    background: "#ffffff",
    red: "#e6b49e",
    analogousGreen: "#9ee6d8",
    analogousBlue: "#9eace6",
    triadicPurple: "#b49ee6",
    triadicPink: "#e69ed0",
  },
  text: {
    dark: "#424651",
    light: "#757986",
    white: "#ffffff",
  },
};

let theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 25,
    },
  },
  palette: {
    primary: {
      main: colours.theme.primary,
      contrastText: colours.text.white,
    },
    secondary: {
      main: colours.theme.secondary,
      contrastText: colours.text.white,
    },
    text: {
      primary: colours.text.dark,
      secondary: colours.theme.secondary,
      disabled: colours.text.light,
      hint: colours.theme.secondary,
    },
    background: {
      default: colours.theme.background,
    },
  },
  shadows: ["none", "none", "none", "none", "none", "none"],
});
theme = responsiveFontSizes(theme);

export default theme;
