import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Container, makeStyles } from "@material-ui/core";
import Treatment from "./Treatment";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    maxWidth: "90%",
    margin: "auto",
    paddingBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
  },
}));

export default function Treatments() {
  const classes = useStyles();
  const heading = "Treatments";

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h2" align="center" gutterBottom>
          {heading}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          className={classes.subtitle}
          gutterBottom
          paragraph
        >
          Please don't hesitate to reach out if the service you need is not
          listed here. I offer comprehensive treatments and services, including
          but not limited to:
        </Typography>
        <Box>
          <Treatment serviceType="Nails (Including Ingrown)">
            Toenail trimming and treatment for ingrown toenails, thickened nails
            and fungal infections
          </Treatment>
          <Treatment serviceType="Calluses and Corn">
            Removal and treatment of calluses and corns
          </Treatment>
          <Treatment serviceType="Vascular Assessment">
            Evaluation of the health of your veins and arteries, checking for
            underlying diseases.
          </Treatment>
          <Treatment serviceType="Neurological Examination">
            Examination and assessment of your neurological condition; muscle
            degredation, involuntary movements and sensation.
          </Treatment>
        </Box>
      </Container>
    </div>
  );
}
